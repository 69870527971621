'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Timer = function () {
    function Timer(player) {
        _classCallCheck(this, Timer);
        this.player = player;
        window.requestAnimationFrame = function () {
            return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        }();
        this.types = [
            'loading',
            'info',
            'fps'
        ];
        this.init();
    }
    _createClass(Timer, [
        {
            key: 'init',
            value: function init() {
                var _this = this;
                this.types.map(function (item) {
                    if (item !== 'fps') {
                        _this['init' + item + 'Checker']();
                    }
                    return item;
                });
            }
        },
        {
            key: 'initloadingChecker',
            value: function initloadingChecker() {
                var _this2 = this;
                var lastPlayPos = 0;
                var currentPlayPos = 0;
                var bufferingDetected = false;
                this.loadingChecker = setInterval(function () {
                    if (_this2.enableloadingChecker) {
                        currentPlayPos = _this2.player.video.currentTime;
                        if (!bufferingDetected && currentPlayPos === lastPlayPos && !_this2.player.video.paused) {
                            _this2.player.container.classList.add('dplayer-loading');
                            bufferingDetected = true;
                        }
                        if (bufferingDetected && currentPlayPos > lastPlayPos && !_this2.player.video.paused) {
                            _this2.player.container.classList.remove('dplayer-loading');
                            bufferingDetected = false;
                        }
                        lastPlayPos = currentPlayPos;
                    }
                }, 100);
            }
        },
        {
            key: 'initfpsChecker',
            value: function initfpsChecker() {
                var _this3 = this;
                window.requestAnimationFrame(function () {
                    if (_this3.enablefpsChecker) {
                        _this3.initfpsChecker();
                        if (!_this3.fpsStart) {
                            _this3.fpsStart = new Date();
                            _this3.fpsIndex = 0;
                        } else {
                            _this3.fpsIndex++;
                            var fpsCurrent = new Date();
                            if (fpsCurrent - _this3.fpsStart > 1000) {
                                _this3.player.infoPanel.fps(_this3.fpsIndex / (fpsCurrent - _this3.fpsStart) * 1000);
                                _this3.fpsStart = new Date();
                                _this3.fpsIndex = 0;
                            }
                        }
                    } else {
                        _this3.fpsStart = 0;
                        _this3.fpsIndex = 0;
                    }
                });
            }
        },
        {
            key: 'initinfoChecker',
            value: function initinfoChecker() {
                var _this4 = this;
                this.infoChecker = setInterval(function () {
                    if (_this4.enableinfoChecker) {
                        _this4.player.infoPanel.update();
                    }
                }, 1000);
            }
        },
        {
            key: 'enable',
            value: function enable(type) {
                this['enable' + type + 'Checker'] = true;
                if (type === 'fps') {
                    this.initfpsChecker();
                }
            }
        },
        {
            key: 'disable',
            value: function disable(type) {
                this['enable' + type + 'Checker'] = false;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                var _this5 = this;
                this.types.map(function (item) {
                    _this5['enable' + item + 'Checker'] = false;
                    _this5[item + 'Checker'] && clearInterval(_this5[item + 'Checker']);
                    return item;
                });
            }
        }
    ]);
    return Timer;
}();
exports.default = Timer;