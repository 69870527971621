'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _utils = require('./utils');
var _utils2 = _interopRequireDefault(_utils);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var User = function () {
    function User(player) {
        _classCallCheck(this, User);
        this.storageName = {
            opacity: 'dplayer-danmaku-opacity',
            volume: 'dplayer-volume',
            unlimited: 'dplayer-danmaku-unlimited',
            danmaku: 'dplayer-danmaku-show',
            subtitle: 'dplayer-subtitle-show'
        };
        this.default = {
            opacity: 0.7,
            volume: player.options.hasOwnProperty('volume') ? player.options.volume : 0.7,
            unlimited: (player.options.danmaku && player.options.danmaku.unlimited ? 1 : 0) || 0,
            danmaku: 1,
            subtitle: 1
        };
        this.data = {};
        this.init();
    }
    _createClass(User, [
        {
            key: 'init',
            value: function init() {
                for (var item in this.storageName) {
                    var name = this.storageName[item];
                    this.data[item] = parseFloat(_utils2.default.storage.get(name) || this.default[item]);
                }
            }
        },
        {
            key: 'get',
            value: function get(key) {
                return this.data[key];
            }
        },
        {
            key: 'set',
            value: function set(key, value) {
                this.data[key] = value;
                _utils2.default.storage.set(this.storageName[key], value);
            }
        }
    ]);
    return User;
}();
exports.default = User;