'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Bezel = function () {
    function Bezel(container) {
        var _this = this;
        _classCallCheck(this, Bezel);
        this.container = container;
        this.container.addEventListener('animationend', function () {
            _this.container.classList.remove('dplayer-bezel-transition');
        });
    }
    _createClass(Bezel, [{
            key: 'switch',
            value: function _switch(icon) {
                this.container.innerHTML = icon;
                this.container.classList.add('dplayer-bezel-transition');
            }
        }]);
    return Bezel;
}();
exports.default = Bezel;