'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _play = require('../assets/play.svg');
var _play2 = _interopRequireDefault(_play);
var _pause = require('../assets/pause.svg');
var _pause2 = _interopRequireDefault(_pause);
var _volumeUp = require('../assets/volume-up.svg');
var _volumeUp2 = _interopRequireDefault(_volumeUp);
var _volumeDown = require('../assets/volume-down.svg');
var _volumeDown2 = _interopRequireDefault(_volumeDown);
var _volumeOff = require('../assets/volume-off.svg');
var _volumeOff2 = _interopRequireDefault(_volumeOff);
var _full = require('../assets/full.svg');
var _full2 = _interopRequireDefault(_full);
var _fullWeb = require('../assets/full-web.svg');
var _fullWeb2 = _interopRequireDefault(_fullWeb);
var _setting = require('../assets/setting.svg');
var _setting2 = _interopRequireDefault(_setting);
var _right = require('../assets/right.svg');
var _right2 = _interopRequireDefault(_right);
var _comment = require('../assets/comment.svg');
var _comment2 = _interopRequireDefault(_comment);
var _commentOff = require('../assets/comment-off.svg');
var _commentOff2 = _interopRequireDefault(_commentOff);
var _send = require('../assets/send.svg');
var _send2 = _interopRequireDefault(_send);
var _pallette = require('../assets/pallette.svg');
var _pallette2 = _interopRequireDefault(_pallette);
var _camera = require('../assets/camera.svg');
var _camera2 = _interopRequireDefault(_camera);
var _subtitle = require('../assets/subtitle.svg');
var _subtitle2 = _interopRequireDefault(_subtitle);
var _loading = require('../assets/loading.svg');
var _loading2 = _interopRequireDefault(_loading);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
var Icons = {
    play: _play2.default,
    pause: _pause2.default,
    volumeUp: _volumeUp2.default,
    volumeDown: _volumeDown2.default,
    volumeOff: _volumeOff2.default,
    full: _full2.default,
    fullWeb: _fullWeb2.default,
    setting: _setting2.default,
    right: _right2.default,
    comment: _comment2.default,
    commentOff: _commentOff2.default,
    send: _send2.default,
    pallette: _pallette2.default,
    camera: _camera2.default,
    subtitle: _subtitle2.default,
    loading: _loading2.default
};
exports.default = Icons;