'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _utils = require('./utils');
var _utils2 = _interopRequireDefault(_utils);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var FullScreen = function () {
    function FullScreen(player) {
        var _this = this;
        _classCallCheck(this, FullScreen);
        this.player = player;
        this.player.events.on('webfullscreen', function () {
            _this.player.resize();
        });
        this.player.events.on('webfullscreen_cancel', function () {
            _this.player.resize();
            _utils2.default.setScrollPosition(_this.lastScrollPosition);
        });
        var fullscreenchange = function fullscreenchange() {
            _this.player.resize();
            if (_this.isFullScreen('browser')) {
                _this.player.events.trigger('fullscreen');
            } else {
                _utils2.default.setScrollPosition(_this.lastScrollPosition);
                _this.player.events.trigger('fullscreen_cancel');
            }
        };
        var docfullscreenchange = function docfullscreenchange() {
            var fullEle = document.fullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (fullEle && fullEle !== _this.player.container) {
                return;
            }
            _this.player.resize();
            if (fullEle) {
                _this.player.events.trigger('fullscreen');
            } else {
                _utils2.default.setScrollPosition(_this.lastScrollPosition);
                _this.player.events.trigger('fullscreen_cancel');
            }
        };
        if (/Firefox/.test(navigator.userAgent)) {
            document.addEventListener('mozfullscreenchange', docfullscreenchange);
            document.addEventListener('fullscreenchange', docfullscreenchange);
        } else {
            this.player.container.addEventListener('fullscreenchange', fullscreenchange);
            this.player.container.addEventListener('webkitfullscreenchange', fullscreenchange);
            document.addEventListener('msfullscreenchange', docfullscreenchange);
            document.addEventListener('MSFullscreenChange', docfullscreenchange);
        }
    }
    _createClass(FullScreen, [
        {
            key: 'isFullScreen',
            value: function isFullScreen() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                switch (type) {
                case 'browser':
                    return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
                case 'web':
                    return this.player.container.classList.contains('dplayer-fulled');
                }
            }
        },
        {
            key: 'request',
            value: function request() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                var anotherType = type === 'browser' ? 'web' : 'browser';
                var anotherTypeOn = this.isFullScreen(anotherType);
                if (!anotherTypeOn) {
                    this.lastScrollPosition = _utils2.default.getScrollPosition();
                }
                switch (type) {
                case 'browser':
                    if (this.player.container.requestFullscreen) {
                        this.player.container.requestFullscreen();
                    } else if (this.player.container.mozRequestFullScreen) {
                        this.player.container.mozRequestFullScreen();
                    } else if (this.player.container.webkitRequestFullscreen) {
                        this.player.container.webkitRequestFullscreen();
                    } else if (this.player.video.webkitEnterFullscreen) {
                        this.player.video.webkitEnterFullscreen();
                    } else if (this.player.video.webkitEnterFullScreen) {
                        this.player.video.webkitEnterFullScreen();
                    } else if (this.player.container.msRequestFullscreen) {
                        this.player.container.msRequestFullscreen();
                    }
                    break;
                case 'web':
                    this.player.container.classList.add('dplayer-fulled');
                    document.body.classList.add('dplayer-web-fullscreen-fix');
                    this.player.events.trigger('webfullscreen');
                    break;
                }
                if (anotherTypeOn) {
                    this.cancel(anotherType);
                }
            }
        },
        {
            key: 'cancel',
            value: function cancel() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                switch (type) {
                case 'browser':
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.webkitCancelFullscreen) {
                        document.webkitCancelFullscreen();
                    } else if (document.msCancelFullScreen) {
                        document.msCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    break;
                case 'web':
                    this.player.container.classList.remove('dplayer-fulled');
                    document.body.classList.remove('dplayer-web-fullscreen-fix');
                    this.player.events.trigger('webfullscreen_cancel');
                    break;
                }
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'browser';
                if (this.isFullScreen(type)) {
                    this.cancel(type);
                } else {
                    this.request(type);
                }
            }
        }
    ]);
    return FullScreen;
}();
exports.default = FullScreen;